import { Link } from "react-router-dom";

const UserList = () => {
    return (
        <>
            <h1>Lista de Usuários</h1>
            <Link to={"/admin/usuarios/registrar"}>
                <button type="button" className="btn btn-primary">
                    Add Usuário
                </button>
            </Link>
        </>
    );
};
export default UserList;
