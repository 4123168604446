import styled, { css } from "styled-components";

export const GridContainer = styled.div`
    ${() => css`
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        padding-top: 20px;
        gap: 20px;
    `}
`;
