import InnerPageContent from "../../components/InnerPageContent";
import InnerPageHeader from "../../components/InnerPageHeader";
import InnerPagesLayout from "../InnerPagesLayout";

const Missao = () => {
    return (
        <InnerPagesLayout>
            <InnerPageHeader title={"Missão, Visão e Valores"} />
            <InnerPageContent>
                <h1 className="display-6">Missão</h1>
                <p className="lead">
                    O CINTESP.Br tem por missão desenvolver inovação tecnológica
                    assistiva, viabilizando, em um menor tempo possível, a
                    transferência destas tecnologias para a sociedade e o setor
                    produtivo.
                </p>
                <h1 className="display-6">Visão</h1>
                <p className="lead">
                    Ser referência nacional no desenvolvimento de inovação em
                    tecnologia assistiva.
                </p>
                <h1 className="display-6">Objetivos</h1>
                <p className="lead">
                    O CINTESP.Br tem por objetivo desenvolver pesquisas e
                    atividades de extensão visando o aumento da acessibilidade,
                    da promoção da saúde, da autonomia, da inclusão social e
                    independência das pessoas com deficiência, pessoas com
                    mobilidade reduzida e pessoas com doenças raras
                </p>
                <h1 className="display-6">Valores</h1>
                <ul>
                    <li className="lead">Ética e transparência</li>
                    <li className="lead">Comprometimento com a ciência</li>
                    <li className="lead">Rigor científico</li>
                    <li className="lead">Respeito às pessoas e à sociedade</li>
                    <li className="lead">Trabalho em equipe</li>
                    <li className="lead">Respeito ao meio ambiente</li>
                    <li className="lead">
                        Comprometimento com a inclusão social e acessibilidade
                    </li>
                    <li className="lead">
                        Comprometimento com o desenvolvimento sustentável
                    </li>
                </ul>

                <h1 className="display-6">Proposta Social</h1>
                <p className="lead">
                    O público alvo do CINTESP.Br são as Pessoas com deficiência
                    (Adultos, crianças e idosos), Pessoas com mobilidade
                    reduzida e Pessoas com Doenças Raras
                </p>
                <h1 className="display-6">Público alvo e área temática</h1>
                <p className="lead">
                    O público alvo do CINTESP.Br são as Pessoas com deficiência
                    (Adultos, crianças e idosos), Pessoas com mobilidade
                    reduzida e Pessoas com Doenças Raras
                </p>
            </InnerPageContent>
        </InnerPagesLayout>
    );
};
export default Missao;
