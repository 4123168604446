import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./index.css";
import "react-image-gallery/styles/css/image-gallery.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { GlobalProvider } from "./context/globalContext";
import Home from "./pages/Home";
import App from "./App";
import Historico from "./pages/Historico";
import QuemSomos from "./pages/QuemSomos";
import Missao from "./pages/Missao";
import FormasAtuacao from "./pages/Formas-atuacao";
import Instalações from "./pages/Instalacoes";
import Pesquisadores from "./pages/Pesquisadores";
import Cnt from "./pages/Cnt";
import Produtos from "./pages/Produtos";
import Parcerias from "./pages/Parcerias";
import Blog from "./pages/Blog";
import PrivateRoute from "./components/PrivateRoute";
import Admin from "./pages/Admin";
import Login from "./pages/Login";
import Register from "./pages/Register";
import UserList from "./pages/Admin/Usuarios/Listar";
import UserForm from "./pages/Admin/Usuarios/Cadastrar";
import PostsList from "./pages/Admin/Posts/Listar";
import PostForm from "./pages/Admin/Posts/Cadastrar";
import ProdutosList from "./pages/Admin/Produtos/Listar";
import ProdutosForm from "./pages/Admin/Produtos/Cadastrar";
import ProductPageDatail from "./components/ProductPageDatail";
import PostPageDatail from "./components/PostPageDatail";

const router = createBrowserRouter([
    {
        element: <App />,
        children: [
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "/historico",
                element: <Historico />,
            },
            {
                path: "/quemsomos",
                element: <QuemSomos />,
            },
            {
                path: "/missao",
                element: <Missao />,
            },
            {
                path: "/formas-de-atuacao",
                element: <FormasAtuacao />,
            },
            {
                path: "/instalacoes",
                element: <Instalações />,
            },
            {
                path: "/parceria",
                element: <Parcerias />,
            },
            {
                path: "/pesquisadores",
                element: <Pesquisadores />,
            },
            {
                path: "/cnt",
                element: <Cnt />,
            },
            {
                path: "/blog",
                element: <Blog />,
            },
            {
                path: "/produtos",
                element: <Produtos />,
            },
            {
                path: "/produtos/detalhe",
                element: <ProductPageDatail />,
            },
            {
                path: "/post/detalhe",
                element: <PostPageDatail />,
            },

            {
                path: "/login",
                element: <Login />,
            },
            {
                path: "/admin",
                element: <PrivateRoute element={<Admin />} />,
                children: [
                    {
                        path: "/admin/cadastrar",
                        element: <Register />,
                    },
                    {
                        path: "/admin/usuarios",
                        element: <UserList />,
                    },
                    {
                        path: "/admin/usuarios/registrar",
                        element: <UserForm />,
                    },
                    {
                        path: "/admin/posts",
                        element: <PostsList />,
                    },
                    {
                        path: "/admin/posts/registrar",
                        element: <PostForm />,
                    },
                    {
                        path: "/admin/produtos",
                        element: <ProdutosList />,
                    },
                    {
                        path: "/admin/produtos/registrar",
                        element: <ProdutosForm />,
                    },
                ],
            },
        ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <GlobalProvider>
        <RouterProvider router={router} />
    </GlobalProvider>,
);
