import { addDoc, collection } from "firebase/firestore";
import React, { useRef, useState } from "react";
import { db, storage } from "../../../../services/firebaseConfig";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import ContentArea from 'jodit-react'
import { useNavigate } from "react-router-dom";

const ProdutosForm = () => {
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [description, setSescription] = useState("");
    const [imageDestak, setImageDestak] = useState("");
    const [videoDestak, setVideoDestak] = useState("");

    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const editor = useRef(null)

    const config = {
        readonly: false,
        height: 400,
    }

    const handleUpdateContent = (e) => {
        const editorContent = e
        setSescription(editorContent)
    }

    const productCollectionRef = collection(db, "produtos");

    const handleSaveProduct = async (e) => {
        e.preventDefault();

        if (file) {
            const storageRef = ref(storage, `images/products/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setProgress(progress);
                },
                (error) => {
                    alert(error.message);
                },
                async () => {
                    const url = await getDownloadURL(uploadTask.snapshot.ref);
                    setImageDestak(url);
                    await addDoc(productCollectionRef, {
                        name: name,
                        description: description,
                        imageDestak: url,
                        videoDestak: videoDestak,

                    });

                    setName("");
                    setSescription("");
                    setImageDestak("");
                    setVideoDestak("");
                    setFile(null);
                    setProgress(0);
                }
            );
        } else {
            await addDoc(productCollectionRef, {
                name: name,
                description: description,
                imageDestak: "",
                videoDestak: videoDestak,
            });
        }
        navigate('/admin/produtos')
    };

    return (
        <>
            <h1>Formulário de Produtos</h1>
            <div className="form">
                <form onSubmit={handleSaveProduct}>
                    <div className="row">
                        <div className="col-9">
                            <div className="mb-3">
                                <label htmlFor="produto" className="form-label">Nome do Produto</label>
                                <input
                                    type="text"
                                    name="produto"
                                    className="form-control"
                                    id="produto"
                                    placeholder="Título do produto"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="conteudo" className="form-label">Descrição</label>
                                <ContentArea
                                    ref={editor}
                                    value={description}
                                    config={config}
                                    tabIndex={1}
                                    onBlur={handleUpdateContent}
                                    onChange={(newContent) =>{}}
                                />
                            </div>

                        </div>
                        <div className="col-3">
                            <div className="mb-3">
                                <label htmlFor="postImage" className="form-label">Imagem destaque</label>
                                <input
                                    className="form-control"
                                    type="file"
                                    id="productImage"
                                    onChange={(e) => setFile(e.target.files[0])}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="produto" className="form-label">Url do Vídeo</label>
                                <input
                                    type="text"
                                    name="video"
                                    className="form-control"
                                    id="video"
                                    placeholder="http//"
                                    value={videoDestak}
                                    onChange={(e) => setVideoDestak(e.target.value)}
                                />
                            </div>

                            <div className="d-grid gap-2 my-3">
                                <button className="btn btn-primary" type="submit">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};
export default ProdutosForm;
