import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../context/globalContext";
import InnerPagesLayout from "../../pages/InnerPagesLayout";
import InnerPageHeader from "../InnerPageHeader";
import InnerPageContent from "../InnerPageContent";

const ProductPageDetail = () => {
    const {
        state: { productDetail },
    } = useContext(GlobalContext);

    useEffect(() => {
        console.log(productDetail);
    }, [productDetail]);

    const getYouTubeVideoId = (url) => {
        const urlPatterns = [
            /(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/|v\/|playlist\?list=)?([^"&?\/\s]{11})/,
            /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^"&?\/\s]{11})/,
        ];

        for (const pattern of urlPatterns) {
            const match = url.match(pattern);
            if (match) {
                return match[1];
            }
        }

        return null;
    };

    const videoId = getYouTubeVideoId(productDetail.videoDestak);

    return (
        <InnerPagesLayout>
            <InnerPageHeader title={productDetail.name} />
            <InnerPageContent>
                <div className="row">
                    <div className="col-md-6">
                        <img
                            className="img-fluid"
                            src={productDetail.imageDestak}
                            width={"100%"}
                            alt=""
                        />
                    </div>

                    <div className="col-md-6">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: productDetail.description,
                            }}
                        />
                    </div>
                </div>
                <hr />
                <h1 className="display-5 my-3">Demonstração em vídeo</h1>
                <iframe
                    width="100%"
                    height="400px"
                    src={`https://www.youtube.com/embed/${videoId}?autoplay=1&loop=1&playlist=${videoId}`}
                    title={productDetail.name}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                />
            </InnerPageContent>
        </InnerPagesLayout>
    );
};

export default ProductPageDetail;
