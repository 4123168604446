import img1 from "../../_assets/album/IMG_6393-scaled.jpg";
import img2 from "../../_assets/album/Sem-titulo-1-650x537.jpg";
import img3 from "../../_assets/album/Sem-titulo-5-1365x909.png";
import img4 from "../../_assets/album/Solene-630x630.png";
import img5 from "../../_assets/album/WhatsApp-Image-2022-08-22-at-16.20.43-1024x682.jpeg";
import img6 from "../../_assets/album/adeviudi.png";
import img7 from "../../_assets/album/dest-768x622.jpg";
import img8 from "../../_assets/album/cad2-768x677.jpg";

const imagesHome = [
    {
        original: img1,
        thumbnail: img1,
    },
    {
        original: img2,
        thumbnail: img2,
    },
    {
        original: img3,
        thumbnail: img3,
    },
    {
        original: img4,
        thumbnail: img4,
    },
    {
        original: img5,
        thumbnail: img5,
    },
    {
        original: img6,
        thumbnail: img6,
    },
    {
        original: img7,
        thumbnail: img7,
    },
    {
        original: img8,
        thumbnail: img8,
    },
];
export { imagesHome };
