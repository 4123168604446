import SideBar from "../SideBar";

const InnerPageContent = (props) => {
    return (
        <div className="container py-5">
            <div className="row">
                <div className="col-md-9 col-sm-12 px-5">{props.children}</div>
                <div className="col-md-3"><SideBar /> </div>
            </div>
        </div>
    );
};
export default InnerPageContent;
