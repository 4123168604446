import { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../context/globalContext";

const UserInfo = ({ user }) => {
    const { setState } = useContext(GlobalContext);
    const currentUser = user.user;
    return (
        <div className="user-info">
            <div className="container user-info-content">
                <div>Olá {currentUser.email}</div>
                <Link
                    style={{ color: "#ffff", textDecoration: "none" }}
                    to={"/admin"}
                >
                    Ir para Admin
                </Link>
                <Link
                    style={{ color: "#ffff", textDecoration: "none" }}
                    onClick={() =>
                        setState((prevContext) => ({
                            ...prevContext,
                            showLogoutModal: true,
                        }))
                    }
                >
                    Logout
                </Link>
            </div>
        </div>
    );
};
export default UserInfo;
