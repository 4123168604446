import InnerPageContent from "../../components/InnerPageContent";
import InnerPageHeader from "../../components/InnerPageHeader";
import InnerPagesLayout from "../InnerPagesLayout";

const Instalações = () => {
    return (
        <InnerPagesLayout>
            <InnerPageHeader title={"Sede e Instalações"} />
            <InnerPageContent>
                <p className="lead">
                    O CINTESP está instalado em dois espaços. Um espaço faz
                    parte de um convênio com o MUNICÍPIO/FUTEL (Convênio No
                    06/2018) e outro espaço na Universidade Federal de
                    Uberlândia.
                </p>
                <p className="lead">
                    <b> Convênio Municipio/Futel: </b> Área de 220 m2 situada na
                    Arena Multiuso Presidente Tancredo Neves “Ginásio
                    Sabiazinho”, localizado na Av. Anselmo Alves dos Santos,
                    3415 – Bairro Tibery, CEP: 38405-167, Uberlândia-MG.
                </p>
                <p className="lead">
                    <b> UFU:</b> Área de 130 m2 situada no Laboratório de
                    Projetos Mecânicos/FEMEC/UFU, localizado na Av. João Naves
                    de Ávila, 2121, Campus Santa Mônica, Bl. CEP: 38400-902,
                    Uberlândia-MG.
                </p>
            </InnerPageContent>
        </InnerPagesLayout>
    );
};
export default Instalações;
