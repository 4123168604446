import InnerPageContent from "../../components/InnerPageContent";
import InnerPageHeader from "../../components/InnerPageHeader";
import InnerPagesLayout from "../InnerPagesLayout";
import { GridContainer } from "../../components/GridContainer";
import { wpPosts } from "../../utils/data/data";

const Blog = () => {
    const stripHtmlTags = (html) => {
        const temporalDivElement = document.createElement("div");
        temporalDivElement.innerHTML = html;
        return (
            temporalDivElement.textContent || temporalDivElement.innerText || ""
        );
    };
    return (
        <InnerPagesLayout>
            <InnerPageHeader title={"Blog"} />
            <InnerPageContent>
                {/* <GridContainer> */}
                {wpPosts.map((post) => {
                    const newPost = {
                        id: post.id,
                        date: post.date,
                        modified: post.modified,
                        title: post.title.rendered,
                        content: stripHtmlTags(post.content.rendered),
                        category: "default",
                    };
                    return (
                        <>
                            <p key={newPost.id}>{newPost.content}</p>
                            <hr />
                        </>
                    );
                })}
                {/* </GridContainer> */}
            </InnerPageContent>
        </InnerPagesLayout>
    );
};
export default Blog;
