import { addDoc, collection } from "firebase/firestore";
import React, { useRef, useState } from "react";
import { db, storage } from "../../../../services/firebaseConfig";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import ContentArea from 'jodit-react'
import { useNavigate  } from "react-router-dom";

const PostForm = () => {
    const [titulo, setTitulo] = useState("");
    const [categoria, setCategoria] = useState("");
    const [status, setStatus] = useState("");
    const [image, setImage] = useState("");
    const [progress, setProgress] = useState(0);
    const [publicacao, setPublicacao] = useState("");
    const [file, setFile] = useState(null);
    const editor = useRef(null)
    const [content, setContent] = useState('')

    const navigate = useNavigate();

    const config = {
        readonly: false,
        height: 400,
    }

    const handleUpdateContent = (e) => {
        const editorContent = e
        setContent(editorContent)
    }

    const postCollectionRef = collection(db, "posts");

    const handleSavePost = async (e) => {
        e.preventDefault();

        if (file) {
            const storageRef = ref(storage, `images/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setProgress(progress);
                },
                (error) => {
                    alert(error.message);
                },
                async () => {
                    const url = await getDownloadURL(uploadTask.snapshot.ref);
                    setImage(url);
                    await addDoc(postCollectionRef, {
                        titulo: titulo,
                        conteudo: content,
                        categoria: categoria,
                        status: status,
                        image: url,
                        publicacao: new Date(publicacao),
                        modificado: new Date(),
                    });

                    setTitulo("");
                    setContent("");
                    setCategoria("");
                    setStatus("");
                    setImage("");
                    setPublicacao("");
                    setFile(null);
                    setProgress(0);
                }
            );
        } else {
            await addDoc(postCollectionRef, {
                titulo: titulo,
                conteudo: content,
                categoria: categoria,
                status: status,
                image: "",
                publicacao: new Date(publicacao),
                modificado: new Date(),
            });
        }

        navigate('/admin/posts')
    };

    return (
        <>
            <h1>Formulário de Posts</h1>
            <div className="form">
                <form onSubmit={handleSavePost}>
                    <div className="row">
                        <div className="col-9">
                            <div className="mb-3">
                                <label htmlFor="titulo" className="form-label">Título</label>
                                <input
                                    type="text"
                                    name="titulo"
                                    className="form-control"
                                    id="titulo"
                                    placeholder="Título do Post"
                                    value={titulo}
                                    onChange={(e) => setTitulo(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="conteudo" className="form-label">Conteúdo</label>
                                <ContentArea
                                    ref={editor}
                                    value={content}
                                    config={config}
                                    tabIndex={1}
                                    onBlur={handleUpdateContent}
                                    onChange={(newContent) =>{}}
                                />
                            </div>

                        </div>
                        <div className="col-3">
                            <div className="mb-3">
                                <label htmlFor="status" className="form-label">Status</label>
                                <select
                                    className="form-select"
                                    id="status"
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                >
                                    <option value="rascunho">Salvar Rascunho</option>
                                    <option value="publicado">Publicar</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="categoria" className="form-label">Categoria</label>
                                <select
                                    className="form-select"
                                    id="categoria"
                                    value={categoria}
                                    onChange={(e) => setCategoria(e.target.value)}
                                >
                                    <option value="default">Sem categoria</option>
                                    <option value="destaque">Destaque</option>
                                    <option value="blog">Blog</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="postImage" className="form-label">Imagem destaque</label>
                                <input
                                    className="form-control"
                                    type="file"
                                    id="postImage"
                                    onChange={(e) => setFile(e.target.files[0])}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="publicacao" className="form-label">Postar em</label>
                                <input
                                    className="form-control"
                                    type="date"
                                    id="publicacao"
                                    value={publicacao}
                                    onChange={(e) => setPublicacao(e.target.value)}
                                />
                            </div>
                            <div className="d-grid gap-2 my-3">
                                <button className="btn btn-primary" type="submit">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default PostForm;
