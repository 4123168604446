import { addDoc, collection } from "firebase/firestore";
import { useState } from "react";
import { db } from "../../services/firebaseConfig";

const FormContato = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const userColletctionRef = collection(db, "mensagens");
    const clearForm = () => {
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
    };
    const createDataOnFirebase = async (e) => {
        e.preventDefault();
        await addDoc(userColletctionRef, {
            nome: name,
            email: email,
            phone: phone,
            message: message,
        });
        clearForm();
    };
    return (
        <form onSubmit={createDataOnFirebase}>
            <div className="container">
                <div className="mb-3">
                    <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                    >
                        Seu Nome
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Digite seu nome"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                    />
                </div>
                <div className="mb-3">
                    <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                    >
                        Seu E-mail
                    </label>
                    <input
                        type="email"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Digite seu Email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                    />
                </div>
                <div className="mb-3">
                    <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                    >
                        Telefone para contato
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Informe um telefone para contato"
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                    />
                </div>

                <div className="mb-3">
                    <label
                        htmlFor="exampleFormControlTextarea1"
                        className="form-label"
                    >
                        Deixe seu recado
                    </label>
                    <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="4"
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                    ></textarea>
                </div>
                <button type="submit" className="btn btn-primary btn-lg mx-3">
                    Envair
                </button>
                <button type="reset" className="btn btn-secondary btn-lg mx-3">
                    Limpar Formulário
                </button>
            </div>
        </form>
    );
};
export default FormContato;
