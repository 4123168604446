import InnerPageContent from "../../components/InnerPageContent";
import InnerPageHeader from "../../components/InnerPageHeader";
import InnerPagesLayout from "../InnerPagesLayout";

const Produtos = () => {
    return (
        <InnerPagesLayout>
            <InnerPageHeader title={"Inovações Tecnológicas"} />
            <InnerPageContent>
                <h1 className="display-6">
                    Conheça as Inovações Tecnológicas do CINTESP.Br
                </h1>
                <p className="lead">
                    Atualmente, o Brasil é uma potência paralímpica, estando
                    entre as dez forças do esporte paralímpico mundial. Desde os
                    jogos de Londres, em 2012, onde os atletas Brasileiros
                    atingiram a 7ª colocação no quadro de medalhas, o esporte
                    paralímpico cresceu alcançando o 8º lugar no ranking de
                    medalhas na Olimpíada de 2016. Para isso, os investimentos
                    estatais foram fundamentais e o papel do Comitê Paralímpico
                    Brasileiro (CPB) vêm sendo de fundamental importância para
                    esta evolução. Neste caso, o Centro Paralímpico Brasileiro,
                    inaugurado em fevereiro de 2016, vêm auxiliando os diversos
                    atletas com uma grande infraestrutura para treinamento em
                    diversas modalidades esportivas.
                </p>
                <p className="lead">
                    As principais contribuições das inovações tecnológicas nos
                    esportes paralímpicos estão relacionadas com o
                    desenvolvimento de sistemas, equipamentos e dispositivos que
                    ajudam o atleta a atingir o seu máximo potencial, melhorando
                    o seu desempenho com intuito de alcançar resultados de alta
                    performance. Estas inovações, portanto, são muito
                    importantes para a melhoria da saúde por meio do esporte
                    para as pessoas com deficiência, ou mesmo, para os atletas
                    Brasileiros que podem conquistar resultados importantes na
                    carreira.
                </p>
                <p className="lead">
                    A área tecnológica em esportes paralímpicos é carente de
                    equipamentos com tecnologia nacional. Grande parte dos
                    equipamentos existentes são importados, e, paralelamente, é
                    reduzido o número de “Atores” que atuam com pesquisas
                    aplicadas em inovações tecnológicas voltadas para o
                    paradesporto.
                </p>
                <p className="lead">
                    A importância do CINTESP.Br, atuando em consonância com o
                    MCTIC e CPB é a sua capacidade de reestruturar, integrar e
                    coordenar inovações tecnológicas em esportes paralímpicos
                    através do desenvolvimento de produtos em consonância com as
                    necessidades dos atletas, além de uma participação direta
                    com a sociedade. Com isso, as inovações poderão melhorar a
                    qualidade de vida e a independência desta população e,
                    indiretamente, promover o aumento da inclusão social.
                </p>
            </InnerPageContent>
        </InnerPagesLayout>
    );
};
export default Produtos;
