import InnerPageContent from "../../components/InnerPageContent";
import InnerPageHeader from "../../components/InnerPageHeader";
import InnerPagesLayout from "../InnerPagesLayout";

const Historico = () => {
    return (
        <InnerPagesLayout>
            <InnerPageHeader title={"Histórico"} />
            <InnerPageContent>
                <p className="lead">
                    Vinculado à Universidade Federal de Uberlândia, o{" "}
                    <b>
                        Centro Brasileiro de Referência em Inovação Tecnológica
                        Assistiva - CINTESP.Br
                    </b>
                    , foi criado em 2012, em edital lançado pelo Ministério da
                    Ciência, Tecnologia e Inovação (MCTI) no âmbito do Plano
                    Viver Sem Limites. Na oportunidade, o nome do centro era{" "}
                    <b>
                        “Núcleo De Habilitação/Reabilitação em Esportes
                        Paralímpicos - NH/RESP”
                    </b>
                    , com a proposta de ser um dos principais núcleos de TA do
                    Brasil, na área do esporte paralímpico.
                </p>
                <p className="lead">
                    Em 2019, em função de diversos projetos inovadores na área
                    do Esporte Paralímpico, o NH/RESP passou a denominar-se{" "}
                    <b>
                        “Centro Brasileiro de Referência em Inovações
                        Tecnológicas para Esportes Paralímpicos –
                        CINTESP.Br/UFU”.
                    </b>
                </p>
                <p className="lead">
                    Atuando com diversas parcerias institucionais, entre elas, a
                    Prefeitura de Uberlândia por meio da Fundação Uberlandense
                    de Turismo, Esporte e Lazer – FUTEL e a Universidade Federal
                    de Uberlândia, o CINTESP.Br estruturou-se internamente com o
                    objetivo de efetuar a transferência das tecnologias para a
                    sociedade e setor produtivo, em um menor tempo possível. A
                    melhoria da sua infraestrutura foi possível graças a
                    financiamentos do Ministério da Ciência, Tecnologia e
                    Inovação (MCTI) e do Ministério Público do
                    Trabalho/Uberlândia (MPT/Uberlândia).
                </p>
                <p className="lead">
                    Em 2019 CINTESP.Br/UFU recebeu financiamentos do Ministério
                    da Ciência, Tecnologia e Inovações (MCTI) e do Ministério
                    Público do Trabalho/Região Uberlândia (MPT/Uberlândia) para
                    desenvolver, em parceria com o Comitê Paralímpico Brasileiro
                    (CPB), “Inovações Tecnológicas” em esportes paralímpicos. O
                    aporte financeiro fortalece as ações e gera uma rede
                    colaborativa com pesquisadores de outros estados, empresas,
                    pessoas com deficiência, atletas/paratletas e somando
                    parcerias com instituições de serviços e a Prefeitura
                    Municipal, por meio da Fundação de Esporte Turismo e Lazer
                    (FUTEL) fortalece o ambiente que Uberlândia vem construindo
                    como referência na iniciação e apoio ao esporte paralímpico
                    torando cada vez mais forte a estrutura esportiva do
                    Município sede, a cidade de Uberlândia.
                </p>
                <p className="lead">
                    Atuando com diversas parcerias institucionais, entre elas, a
                    Prefeitura de Uberlândia por meio da Fundação Uberlandense
                    de Turismo, Esporte e Lazer – FUTEL e a Universidade Federal
                    de Uberlândia, o CINTESP.Br estruturou-se internamente com o
                    objetivo de efetuar a transferência das tecnologias para a
                    sociedade e setor produtivo, em um menor tempo possível. A
                    melhoria da sua infraestrutura foi possível graças a
                    financiamentos do Ministério da Ciência, Tecnologia e
                    Inovação (MCTI) e do Ministério Público do
                    Trabalho/Uberlândia (MPT/Uberlândia).
                </p>
                <p className="lead">
                    Apesar da expressiva atuação na área do paradesporto, o
                    CINTESP.Br/UFU vem desenvolvendo produtos assistivos em
                    diversas linhas. Por este motivo, em 2024, visando dar maior
                    visibilidade em toda a sua área de atuação, o CINTESP.Br/UFU
                    alterou seu nome para{" "}
                    <b>
                        {" "}
                        “CENTRO BRASILEIRO DE REFERÊNCIA EM INOVAÇÃO TECNOLÓGICA
                        ASSISTIVA – CINTESP.Br”.
                    </b>
                </p>
                <p className="lead">
                    Em 2024, a FINEP aprovou a rede de laboratórios formada pelo
                    CINTESP.Br/UFU, Laboratório de Projetos Mecânicos (LPM/UFU),
                    Laboratório de Projeto Mecânico Automação, Fabricação e
                    Validação (FEMEC MAKER/UFU), Laboratório de Modelos e
                    Protótipos (LAMOP/UFU), Laboratório de Usabilidade e Design
                    Ergonômico (LUDE/UFU) e Núcleo de Tecnologia Assistiva
                    (NTA/UFU). Esta rede fará o processo de integração, gestão,
                    coordenação e articulação do SiSAssistiva/MCTI, formado por
                    27 redes de laboratórios de todo o Brasil. Este processo de
                    coordenação será organizado e conduzido em parceria com a
                    <b>
                        {" "}
                        Secretaria de Ciência e Tecnologia para o
                        Desenvolvimento Social (SEDES/MCTI).
                    </b>
                </p>
            </InnerPageContent>
        </InnerPagesLayout>
    );
};
export default Historico;
