import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { GlobalContext } from "../../context/globalContext";

const PrivateRoute = ({ element }) => {
    const { state } = useContext(GlobalContext);
    const location = useLocation();

    if (!state.isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return element;
};
export default PrivateRoute;
