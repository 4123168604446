import { useContext } from "react";
import { GlobalContext } from "../../../context/globalContext";
import { useNavigate } from "react-router-dom";

const CarrolselDestakItem = (props) => {
    const card = props.card;
    const { setState } = useContext(GlobalContext);
    const navigate = useNavigate();

    const destakDetail = (card) => {
        setState((prevContext) => ({
            ...prevContext,
            destakDetail: card,
        }));
        navigate("/post/detalhe");
    };

    return (
        <div
            className="item-destak"
            key={`${card.id}`}
            onClick={() => destakDetail(card)}
        >
            <img src={`${card.image}`} alt="" />
            <div className="legend">
                <p>{card.titulo}</p>
            </div>
        </div>
    );
};
export default CarrolselDestakItem;
