import { Link } from "react-router-dom";

const CarrolselItem = (props) => {
    const card = props.card;
    return (
        <div className="item" key={card.cardId}>
            <Link to={card.cardLink} target="blank">
                <img src={card.cardImage} alt="" />
            </Link>
        </div>
    );
};
export default CarrolselItem;
