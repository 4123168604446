import InnerPageContent from "../../components/InnerPageContent";
import InnerPageHeader from "../../components/InnerPageHeader";
import InnerPagesLayout from "../InnerPagesLayout";

const Cnt = () => {
    return (
        <InnerPagesLayout>
            <InnerPageHeader title={"Centro Nacional de Tecnologias"} />
            <InnerPageContent>
                <p className="lead">
                    No Lançamento de inovações em Tecnologia Assistiva – LANCE
                    PcD 2022, realizado em Uberlândia, foram apresentados para a
                    sociedade brasileira, 21 produtos inovadores de tecnologia
                    assistiva desenvolvidos no CINTESP.Br/UFU. No evento também
                    foi apresentado o novo “Centro Nacional de Tecnologias para
                    Pessoas com Deficiência e Doenças Raras – CNT-MCTI” a ser
                    implantado na cidade de Uberlândia. O CNT-MCTI é uma
                    iniciativa do Ministério de Ciência, Tecnologia e Inovações
                    (CNT-MCTI) em parceria com a Universidade Federal de
                    Uberlândia, o grupo Algar e o CINTESP.Br.
                </p>
                <p className="lead">
                    O CNT-MCTI será uma referência no país quanto a busca de
                    ações para a transferência de tecnologias para a sociedade
                    brasileira com geração empreendedora e apoio a criação de
                    startups com foco em tecnologia assistiva. Este novo centro,
                    faz parte da Política de Desenvolvimento Científico,
                    Tecnológico e Inovação para Qualidade de Vida do Ministério
                    da Ciência, Tecnologia e Inovação e será dedicado à criação
                    de um ambiente de colaboração entre academia, governo e o
                    setor privado. Com mais esta ação, o Governo Federal espera
                    ampliar o incentivo e fortalecer o atual alinhamento em
                    competências em ciência, tecnologia, empreendedorismo e
                    inovação voltados para tecnologia assistiva. As áreas
                    contempladas de atuação do CNT-MCTI serão para o
                    desenvolvimento de inovações em tecnologia assistiva para a
                    saúde, a educação, o esporte, o lazer e a vida-diária.
                </p>
                <p className="lead">
                    O objetivo com a implantação, em Uberlândia-MG, do CNT-MCTI,
                    com a participação da UFU, do CINTESP.Br/UFU, do Grupo Algar
                    e outros parceiros será o de proporcionar o bem-estar, a
                    autonomia e a inclusão social e produtiva de pessoas com
                    deficiência, pessoas com mobilidade reduzida e pessoas com
                    doenças raras.
                </p>
                <p className="lead">
                    A estrutura do CNT envolve um projeto com recursos de R$ 11
                    milhões, em sua fase inicial, e encontra-se em análise na
                    financiadora de projetos, a FINEP, de acordo com o Reitor da
                    UFU, professor Valder Steffen Júnior.
                </p>
                <p className="lead">
                    Pelo projeto, a primeira fase de implantação do CNT-MCTI
                    será de 1300 m2. Estamos trabalhando em coordenação com a
                    secretaria de empreendedorismo e inovação do Ministério da
                    Ciência, Tecnologia e Inovações buscando a cultura
                    empreendedora para a tecnologia assistiva. Enxergamos que
                    aqui podem ser gerados empregos além de inovação e vamos
                    trabalhar fortemente nisso, juntamente com a iniciativa
                    privada. O que nós vemos aqui em Uberlândia é o fruto de
                    todas essas ações”, disse Felipe Bellucci, coordenador-geral
                    de tecnologias habilitadoras (CGTH) da Secretaria de
                    Empreendedorismo e Inovação do MCTI.
                </p>
                <p className="lead">
                    O Objetivo do MCTI com a implantação do CNT-MCTI, em
                    Uberlândia, é trazer para o Brasil, um ambiente ainda mais
                    favorável para que as tecnologias cheguem para as pessoas de
                    maneira mais ágil. “Temos o envolvimento do CINTESP.Br, da
                    rede de laboratórios multiusuários da UFU, das competências
                    instaladas na UFU e em rede de laboratórios que já tem
                    equipamentos instalados e com colaboração de diversos outros
                    parceiros. O CNT é um sonho para a Universidade Federal de
                    Uberlândia e também para a nossa região, muitas pessoas
                    serão beneficiadas pelas atividades desse Centro”, disse o
                    Reitor.
                </p>
            </InnerPageContent>
        </InnerPagesLayout>
    );
};
export default Cnt;
