import React, { useState, useEffect } from "react";

const initialstate = {
    posts: [],
    users: [],
    products: [],
    productDetail: [],
    destakDetail: [],
    imagesGAlery: [],
    currentUser: null,
    showLogoutModal: false,
    isAuthenticated: false,
};

const GlobalContext = React.createContext();
const GlobalProvider = ({ children }) => {
    const [state, setState] = useState(() => {
        const storedState = localStorage.getItem("globalState");
        return storedState ? JSON.parse(storedState) : initialstate;
    });

    useEffect(() => {
        localStorage.setItem("globalState", JSON.stringify(state));
    }, [state]);

    const login = (user) =>
        setState((prevState) => ({
            ...prevState,
            isAuthenticated: true,
            currentUser: user,
        }));
    const logout = () =>
        setState((prevState) => ({ ...prevState, isAuthenticated: false }));

    return (
        <GlobalContext.Provider value={{ state, setState, login, logout }}>
            {children}
        </GlobalContext.Provider>
    );
};

export { GlobalContext, GlobalProvider };
