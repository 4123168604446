import InnerPageContent from "../../components/InnerPageContent/index.jsx";
import InnerPageHeader from "../../components/InnerPageHeader/index.jsx";
import InnerPagesLayout from "../InnerPagesLayout/index.jsx";

const Pesquisadores = () => {
    return (
        <InnerPagesLayout>
            <InnerPageHeader title={"Parceiros e Patrocinadores"} />
            <InnerPageContent>
                <p className="lead">
                    A equipe do CINTESP.Br é de caráter multidisciplinar,
                    retratando um conceito de pesquisa contemporâneo com um
                    papel fundamental para o estudo, o desenvolvimento e a
                    inovação da área de Inovações Tecnológicas voltadas para o
                    Esporte em Geral, principalmente, o Paradesporto. O
                    CINTESP.Br interage com pesquisadores nacionais e
                    internacionais promovendo a disseminação do conhecimento e
                    expandindo grupos de estudo, sendo fundamental para a
                    complementação e sedimentação dos novos conhecimentos em
                    Tecnologias Assistivas voltadas para o esporte e saúde.
                </p>
                <p className="lead">
                    A equipe multidisciplinar possui um elo entre a ciência, a
                    indústria e a sociedade, transportando o resultado
                    experimental para a tecnologia de equipamentos e aparelhos.
                    Os integrantes da equipe CINTESP.Br, em diferentes áreas do
                    conhecimento fazem novos e valiosos contatos profissionais
                    com cerca de 300 trabalhos relacionados com projetos em
                    Tecnologias Assistivas considerando os Profissionais,
                    Estudantes de graduação e Pós-graduação.
                </p>
                <p className="lead">
                    <b>
                        {" "}
                        A seguir são listados os Pesquisadores que desenvolvem
                        pesquisas, direta ou indiretamente, com o CINTESP.Br:
                    </b>
                </p>
            </InnerPageContent>
        </InnerPagesLayout>
    );
};
export default Pesquisadores;
