import { Link } from "react-router-dom";

const MenuItem = (props) => {
    return (
        <div className="admin-menu-Item my-1">
            <Link
                style={{ color: "#ffff", textDecoration: "none" }}
                to={props.url}
            >
                {props.label}
            </Link>
        </div>
    );
};
const AdminSideBar = () => {
    return (
        <aside className="admin-side-bar py-5 px-1">
            <MenuItem url={"/"} label={"Home"} />
            <MenuItem url={"/admin/usuarios"} label={"Usuários"} />
            <MenuItem url={"/admin/posts"} label={"Posts"} />
            <MenuItem url={"/admin/produtos"} label={"Produtos"} />
            <MenuItem url={"/"} label={"Galerias"} />
            <MenuItem url={"/"} label={"Mensagens"} />
        </aside>
    );
};
export default AdminSideBar;
