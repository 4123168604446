import React, { useContext } from "react";
import Modal from "react-modal";
import { GlobalContext } from "../../context/globalContext";

const ModalLogout = ({ isOpen }) => {
    Modal.setAppElement("#root");
    const { setState, logout } = useContext(GlobalContext);

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            borderRadius: "10px",
            padding: "2%",
            boxShadow: " 2px 2px 28px -1px rgba(115,115,115,1)",
            transform: "translate(-50%, -50%)",
        },
    };

    const closeModal = () => {
        setState((prevContext) => ({
            ...prevContext,
            showLogoutModal: false,
        }));
    };

    const handleLogout = () => {
        logout();
        setState((prevContext) => ({
            ...prevContext,
            showLogoutModal: false,
        }));
    };
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <h2 className="display-6">Logout</h2>
            <hr />

            <p className="lead">Tem certeza que deseja sair?</p>
            <div className="d-flex justify-content-evenly">
                <button className="btn btn-secondary" onClick={closeModal}>
                    Cancelar
                </button>
                <button className="btn btn-primary" onClick={handleLogout}>
                    Confirmar
                </button>
            </div>
        </Modal>
    );
};
export default ModalLogout;
