import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

const InnerPagesLayout = (props) => {
    return (
        <>
            <Navbar />
            <div className="inner-page-content">{props.children}</div>
            <Footer />
        </>
    );
};
export default InnerPagesLayout;
