import { useEffect } from "react";
import { Link } from "react-router-dom";
import { getFirestore, getDocs, collection  } from "firebase/firestore";
import { db } from '../../../../services/firebaseConfig'

const ProdutosList = () => {
    const postCollectionRef = collection(db, "posts")
    useEffect(() => {
        const getPosts = async () => {
            const data = await getDocs(postCollectionRef)
            console.log(data)
        }

        getPosts()
    }, [])
    return (
        <>
            <h1>Lista de Produtos</h1>
            <Link to={"/admin/produtos/registrar"}>
                <button type="button" className="btn btn-primary">
                    Add Produto
                </button>
            </Link>
        </>
    );
};
export default ProdutosList;
