import { Link } from "react-router-dom";
import FormContato from "../../FormContato";

const FooterFeatures = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-6 p-3">
                    <p className="display-6">Contato</p>
                    <p className="lead">
                        {" "}
                        <b> Cleudmar A. Amaral </b>
                    </p>
                    <p className="lead">Coordenador Geral CINTESP.Br</p>
                    <hr />
                    <p className="lead">Telefone: +55 34 99158-1188</p>
                    <p className="lead">
                        Endereço:{" "}
                        <Link to={"/instalacoes"}>
                            {" "}
                            <b> Acesse Sede e Instalações</b>
                        </Link>
                    </p>
                    <p className="lead">Email: cintespbr@cintespbr.org</p>
                    <hr />
                    <Link to={"/quemsomos"}>
                        <p className="lead">Sobre o Cintesp.br</p>
                    </Link>
                    <hr />
                    <Link to={"/parceria"}>
                        <p className="lead">Parceiros</p>
                    </Link>
                    <hr />
                    <Link to={"/pesquisadores"}>
                        <p className="lead">Pesquisadores</p>
                    </Link>
                    <hr />
                    <Link to={"/produtos"}>
                        <p className="lead">Produtos</p>
                    </Link>
                </div>
                <div className="col-sm-6 p-3">
                    <p className="lead">Deixe-nos um recado</p>
                    <FormContato />
                </div>
            </div>
        </div>
    );
};
export default FooterFeatures;
