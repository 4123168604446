import InnerPageContent from "../../components/InnerPageContent/index.jsx";
import InnerPageHeader from "../../components/InnerPageHeader/index.jsx";
import InnerPagesLayout from "../InnerPagesLayout/index.jsx";
import {
    parceirosData,
    patrocinadoresData,
} from "../../utils/data/parceirosData.js";
import { GridContainer } from "../../components/GridContainer/index.js";

const Parcerias = () => {
    return (
        <InnerPagesLayout>
            <InnerPageHeader title={"Parceiros e Patrocinadores"} />
            <InnerPageContent>
                <p className="lead">
                    CINTESP.Br, vinculado à Universidade Federal de Uberlândia
                    (UFU), financiado pelo Ministério da Ciência, Tecnologia e
                    Inovação (MCTI) e pelo Ministério Público do Trabalho
                    (MPT/Uberlândia-MG), atuando em formato de redes
                    colaborativas em parceria com a Prefeitura Municipal de
                    Uberlândia por meio da Fundação Uberlandense do Turismo,
                    Esporte e Lazer (Futel), o Comitê Paralímpico Brasileiro
                    (CPB), o Praia Clube de Uberlândia, Grupo Algar e o
                    Instituto Brain/Algar.
                </p>
                <h1 className="display-6">Nossos Parceiros</h1>

                <GridContainer>
                    {parceirosData.map((parceiro) => (
                        <a
                            href={parceiro.cardLink}
                            target="_blank"
                            className="product-display"
                        >
                            <img
                                width={"200px"}
                                src={parceiro.cardImage}
                                alt=""
                            />
                        </a>
                    ))}
                </GridContainer>

                <h1 className="display-6 pt-5">Nossos Patrocinadores</h1>

                <GridContainer>
                    {patrocinadoresData.map((patrocinador) => (
                        <a
                            href={patrocinador.cardLink}
                            target="_blank"
                            className="product-display"
                        >
                            <img
                                width={"200px"}
                                src={patrocinador.cardImage}
                                alt=""
                            />
                        </a>
                    ))}
                </GridContainer>
            </InnerPageContent>
        </InnerPagesLayout>
    );
};
export default Parcerias;
