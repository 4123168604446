import OwlCarousel from "react-owl-carousel";
import CarrolselDestakItem from "./CarrouselDestakItem";

const CarrouselDestak = (props) => {
    const content = props.cards;

    const options = {
        margin: props.margin,
        responsiveClass: true,
        dots: false,
        loop: true,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 2,
            },
            1000: {
                items: props.items,
            },
        },
    };
    return (
        <div className="container">
            <OwlCarousel {...options}>
                {content.map((card) => (
                    <CarrolselDestakItem card={card} key={card.id} />
                ))}
            </OwlCarousel>
        </div>
    );
};
export default CarrouselDestak;
