import InnerPageContent from "../../components/InnerPageContent";
import InnerPageHeader from "../../components/InnerPageHeader";
import InnerPagesLayout from "../InnerPagesLayout";

const QuemSomos = () => {
    return (
        <InnerPagesLayout>
            <InnerPageHeader title={"Quem Somos"} />
            <InnerPageContent>
                <p className="lead">
                    <b> O Cintesp.Br/UFU</b> desenvolve PD&I, atuando em
                    parceria com redes colaborativas, instituições públicas e
                    privadas, empresas, pesquisadores e profissionais
                    interessados na área de Tecnologia Assistiva. Utilizando
                    metodologia científica, as pesquisas são desenvolvidas de
                    acordo com a escala de maturidade tecnológica (TRL), até o
                    processo final de transferência de tecnologia nas áreas da
                    saúde, esporte, vida diária, lazer, mobilidade,
                    acessibilidade e educação inclusiva.
                </p>
                <p className="lead">
                    Os resultados visam melhorar a acessibilidade, a qualidade
                    de vida, a autonomia e a inclusão social das pessoas com
                    deﬁciência, pessoas com mobilidade reduzida e pessoas com
                    doenças raras.
                </p>
            </InnerPageContent>
        </InnerPagesLayout>
    );
};
export default QuemSomos;
