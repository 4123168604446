import { Link } from "react-router-dom";
import logo from "../../_assets/logo.png";
import "./style.css";

const Logo = () => {
    return (
        <Link to={"/"}>
            <img src={logo} alt="Cintesp.br" className="logo img-fluid" />
        </Link>
    );
};
export default Logo;
