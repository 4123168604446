import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../context/globalContext";
import InnerPagesLayout from "../../pages/InnerPagesLayout";
import InnerPageHeader from "../InnerPageHeader";
import InnerPageContent from "../InnerPageContent";
import { Link } from "react-router-dom";

const PostPageDatail = () => {
    const {
        state: { destakDetail },
    } = useContext(GlobalContext);

    useEffect(() => {
        console.log(destakDetail);
    }, [destakDetail]);

    return (
        <InnerPagesLayout>
            <InnerPageHeader title={destakDetail.titulo} />
            <InnerPageContent>
                <div className="row">
                    <div className="col-md-12">
                        <img
                            className="img-fluid"
                            src={`${destakDetail.image}`}
                            width={"100%"}
                            alt=""
                        />
                    </div>

                    <div className="col mt-3">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: destakDetail.conteudo,
                            }}
                        />
                    </div>
                </div>
                <hr />
                <div
                    className="btn btn-primary mx-5"
                    onClick={() => window.history.back()}
                >
                    Voltar
                </div>
            </InnerPageContent>
        </InnerPagesLayout>
    );
};

export default PostPageDatail;
