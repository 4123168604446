import InnerPageContent from "../../components/InnerPageContent";
import InnerPageHeader from "../../components/InnerPageHeader";
import InnerPagesLayout from "../InnerPagesLayout";

const FormasAtuacao = () => {
    return (
        <InnerPagesLayout>
            <InnerPageHeader
                title={"Formas de Atuação e Redes Colaborativas"}
            />
            <InnerPageContent>
                <h1 className="display-6">Objetivo</h1>
                <p className="lead">
                    As ações de pesquisa e extensão são desenvolvidas por
                    pesquisadores e profissionais internos e externos ligados ao
                    CINTESP.Br. Os membros externos são definidos através de
                    acordos e convênios atuando em um formato de rede em
                    projetos que atendam prioridades definidas pela sociedade e
                    por órgãos governamentais em Tecnologias Assistiva nas áreas
                    da saúde, esporte, lazer, vida diária e doenças raras.
                </p>
                <p className="lead">
                    A formação e articulação das redes colaborativas de
                    abrangência nacional considera interesses nacionais que
                    visam a promoção da ciência, da tecnologia e da inovação
                    como mecanismo de consolidação de tecnologias em produtos
                    assistivos que possam ser transferidos para a indústria
                    nacional com a geração de conhecimento, riqueza e qualidade
                    de vida para os brasileiros.
                </p>
                <p className="lead">
                    As Redes colaborativas serão formadas com associações
                    públicas e privadas, empresas, institutos de pesquisa,
                    universidades, laboratórios, profissionais e pesquisadores,
                    interessados em desenvolver inovações tecnológicas voltadas
                    para as pessoas com deficiência, pessoas com mobilidade
                    reduzida e pessoas com doenças raras. As redes colaborativas
                    deverão ser organizadas, especificamente, para o
                    desenvolvimento de pesquisas aplicadas ou experimentais e
                    serão formalizadas através de acordos e convênios.
                </p>
            </InnerPageContent>
        </InnerPagesLayout>
    );
};
export default FormasAtuacao;
