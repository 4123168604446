import ImageGallery from "react-image-gallery";

const ImagesGallery = (props) => {
    const images = props.images;
    return (
        <div className="container">
            <ImageGallery items={images} />;
        </div>
    );
};
export default ImagesGallery;
