import React, { useContext, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Session } from "../../components/Session";
import bgBlue from "../../_assets/bg-blue.png";
import { GlobalContext } from "../../context/globalContext";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { auth } from "../../services/firebaseConfig";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [signInWithEmailAndPassword, user, loading, error] =
        useSignInWithEmailAndPassword(auth);

    const { login } = useContext(GlobalContext);
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogin = () => {
        signInWithEmailAndPassword(email, password);
    };

    if (user) {
        login(user);
        const from = location.state?.from?.pathname || "/";
        navigate(from, { replace: true });
    }
    if (error) {
        return (
            <div class="alert alert-warning" role="alert">
                <p>Erro: {error.message}</p>
            </div>
        );
    }

    return (
        <Session backgroundimage={bgBlue}>
            <div className="login-page-container">
                <div className="auth-form p-5 py-3">
                    <div className="display-6">Login</div>
                    <div className="lead my-3">Acesso restrito para administradores</div>
                    <form className="mt-3 w-100">
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">
                                Email
                            </label>
                            <input
                                type="email"
                                name="email"
                                className="form-control"
                                id="email"
                                placeholder="Informe seu email de usuário"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">
                                Senha
                            </label>
                            <input
                                type="password"
                                name="password"
                                className="form-control"
                                id="password"
                                placeholder="Digite sua senha"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>

                        {/* <a href="#">Esqueceu sua senha ?</a> */}

                        <div className="d-grid gap-2 my-3">
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={handleLogin}
                            >
                                Entrar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Session>
    );
};

export default Login;
