import { Link, Outlet } from "react-router-dom";
import AdminSideBar from "../../components/AdminSideBar";

const Admin = () => {
    return (
        <div className="admin-layout">
            <AdminSideBar />
            <div className="w-100 p-5">
                <Outlet />
            </div>
        </div>
    );
};
export default Admin;
